.layout-footer {
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background-color: var(--white-color);
  transition: 0.5s;
}
