.dhc {
  &_account_state {
    width: 120px;
    border-radius: 15px;
    text-align: center;
    padding: 5px 0px;

    &_active {
      background-color: #ff9933;
    }
    &_pending {
      background-color: #17a2b8;
    }
  }

  &_icon_color {
    color: black;
  }
  &_email_inactive {
    color: #17a2b8;
  }
}
