.appbtnoutline {
  &-primary {
    color: #007bff;
    border-color: #007bff;
    &:hover {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }

  &-secondary {
    color: #6c757d;
    border-color: #6c757d;
    &:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }

  &-success {
    color: #ff9933;
    border-color: #ff9933;
    &:hover {
      color: #fff;
      background-color: #ff9933;
      border-color: #ff9933;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }

  &-danger {
    color: #dc3545;
    border-color: #dc3545;
    &:hover {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }

  &-warning {
    color: #ffc107;
    border-color: #ffc107;
    &:hover {
      color: #fff;
      background-color: #ffc107;
      border-color: #ffc107;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }

  &-info {
    color: #17a2b8;
    border-color: #17a2b8;
    &:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
    }
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
  }
}
