@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,800;0,900;1,600;1,700&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  background: #ebecee;
}

button,
button:hover,
button:focus,
button:active {
  outline: 0 !important;
  /* box-shadow: none !important; */
}

playlist-module input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.m-0 {
  margin: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.form-control.custom-input {
  border-radius: 0;
  font-size: 15px;
  border-color: #e8e8e8;
  min-height: 38px;
  border-radius: 0;
}

.form-control:hover,
.form-control:focus,
.btn:hover,
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.custom-notification {
  background-color: #000;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.custom-notification .notification-custom-content {
  padding: 6px 0;
  flex: 1;
}

.custom-notification .notification-custom-icon {
  margin-right: 10px;
}

.custom-notification .notification-close {
  margin-right: auto;
  margin-left: 10px;
}

.notification-item .notification-close:after {
  font-size: 20px !important;
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.toasterContent .notification-item:before {
  content: none;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.toasterContent .notification-item:before {
  content: none;
}

.page-loader {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}

.deleteError {
  color: red;
}

.custom-check-lbl .MuiTypography-body1 {
  font-size: inherit;
  margin-right: 10px;
}

.loader-pos-center {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

/* .tbl-pos-center {
  margin-top: 30px;
} */
.form-control.month-select .css-yk16xz-control {
  border: 0;
  border-radius: 0;
  min-height: 38px !important;
}

.device-information .date-range-container {
  z-index: inherit !important;
}

.logout {
  margin-left: 10px;
  cursor: pointer;
}

.logout:hover {
  color: #167495;
}

.btn.bold {
  font-weight: 700;
}

.btn.btn-gray {
  border-color: #d9dadd;
}

.drop-outer {
  margin: 0 -1%;
}

.drop-zon {
  display: inline-block;
  background: #f7f6f6;
  border: 2px dashed #d9dadd;
  padding: 0;
  margin: 10px 1% 20px;
  width: 48%;
  text-align: center;
  color: #0d4478;
  cursor: pointer;
}

.drop-zon > div {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zon div:focus {
  outline: 0;
}

.margin-zero {
  margin: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}

.import-lbl {
  margin: 20px 0 10px;
  font-size: 18px;
}

.text-word-wrap {
  word-break: break-all;
}

.disabled-pointer .nav-link:hover {
  cursor: default !important;
}

.inner-container {
  margin-top: 138px;
}

.main .container-fluid {
  padding: 0 !important;
}

.dashboard-header {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 4.75px 0.25px rgba(0, 1, 1, 0.1);
  height: 80px;
  padding: 12px 30px;
  position: fixed;
  z-index: 9;
  width: 100%;
}

.date-range-container {
  position: absolute;
  top: 50px;
  z-index: 99;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.top-nav {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 30px;
}

.top-nav li {
  padding: 0 15px;
}

.device-information {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.device-information .form-control,
.device-information .form-control:hover {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 1, 1, 0.2) !important;
  width: 144px;
  height: 40px;
  outline: 0 !important;
  border: none;
  border-radius: 0;
}

.dashboard-card {
  /* border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 1.96px 0.04px rgba(0, 0, 1, 0.02); */
}

.dashboard-card .inner-header {
  padding: 20px 25px;
  border-bottom: 3px solid #f2f3f9;
  align-items: center;
}

.dashboard-card .inner-header .title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.expt-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #23282c;
  margin: 0 0 0 15px;
  text-decoration: none !important;
}

.expt-btn img {
  margin: 0 5px 0 0;
}

.dashboard-card .inner-body {
  padding: 20px 25px;
}

.inner-card {
  border: 1px solid #f8f8f8;
  height: 230px;
  display: flex;
  padding: 0 34px;
  justify-content: center;
}

.inner-card .custom-title {
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.inner-card .title {
  font-size: 38px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.inner-card .value {
  font-size: 58px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
}

.inner-card .value.current {
  color: #faa330;
}

.inner-card .value.last {
  color: #808080;
}

.growth-value {
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  margin: 0 0 0 20px;
  white-space: nowrap;
}

.rating-container {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
}

.rating-container .item-container {
  flex: 1;
  padding: 0 15px;
}

.rating-container .item-container .item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  border: 1px solid #f8f8f8;
  border-radius: 6px;
}

.rating-container .item-container .item .count-detail {
  margin-left: 15px;
}

.rating-container .item-container .item .count {
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}

.rating-container .item-container .item .percentage {
  font-size: 18px;
  line-height: 1;
  color: #808080;
  margin-bottom: 0px;
}

.dashboard-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background: #000000;
  color: #fff;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.satisfaction-ul {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 40px;
}

.satisfaction-ul li {
  font-size: 13px;
  color: #adb5bd;
  margin-left: 40px;
}

.satisfaction-ul li span {
  display: inline-flex;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  margin-right: 3px;
}

.satisfaction-ul li span.very-poor {
  background: #ee4444;
}
.satisfaction-ul li span.poor {
  background: #f79f3e;
}

.satisfaction-ul li span.fair {
  background: #e1e222;
}

.satisfaction-ul li span.good {
  background: #7dbe43;
}

.satisfaction-ul li span.very-good {
  background: #26973e;
}

.satisfaction-lbl {
  font-size: 18px;
  text-align: center;
}

.satisfaction-lbl.very-bad {
  color: #ee4444;
}

.satisfaction-lbl.bad {
  color: #f79f3e;
}

.satisfaction-lbl.fair {
  color: #e1e222;
}
.satisfaction-lbl.good {
  color: #7dbe43;
}

.satisfaction-lbl.very-good {
  color: #26973e;
}

.warning-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.warning-ul li {
  font-weight: 400;
  padding: 10px 30px;
  border-bottom: 1px solid #f2f3fa;
  display: flex;
  align-items: center;
  min-height: 62px;
}

.warning-ul li:last-child {
  border-bottom: none;
}

.warning-ul li p {
  margin: 0;
}

.warning-ul li strong {
  font-weight: 500;
}

.warning-ul li .index-no {
  color: #808080;
  margin-right: 20px;
}

.fraud-cnt {
  background: #f05252;
  color: #fff;
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 6px;
  margin: 0 8px;
}

.btn.btn-green-gradient {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: 500 !important;
  line-height: 1.571;
  border-radius: 5px !important;
  background: -moz-linear-gradient(
    -7deg,
    rgb(71, 182, 75) 0%,
    rgb(130, 197, 103) 100%
  );
  background: -webkit-linear-gradient(
    -7deg,
    rgb(71, 182, 75) 0%,
    rgb(130, 197, 103) 100%
  );
}

.table-report tr td,
.table-report tr th {
  border: none !important;
  vertical-align: middle;
}

.table-report {
  color: #808080 !important;
  font-size: 14px;
}

.table-report tr th {
  color: #000;
  font-weight: normal !important;
}

.dashboard-type .form-check-label {
  margin-right: 30px;
}

.min-h350 {
  min-height: 350px;
}

.device-table {
  position: relative;
}

.flex-1 {
  flex: 1;
}

.inner-container .acn-link {
  position: fixed;
  top: 28px;
  right: 105px;
  z-index: 1000;
}

.action-buttons.pdf-action {
  padding: 0 0 0 120px;
}

.acn-link {
  margin: 0 15px 0 10px;
}

.acn-link:hover {
  color: #167495;
  cursor: pointer;
}

.form-control.month-select {
  box-shadow: 0px 0px 5px 0px rgba(0, 1, 1, 0.2) !important;
  padding: 0;
  border: 0;
}

.form-control.month-select .css-1uccc91-singleValue {
  color: #5c6873;
}

.form-control.month-select .css-1okebmr-indicatorSeparator {
  display: none;
}

.form-control.month-select .css-tlfecz-indicatorContainer {
  color: #5c6873;
}

.form-control.month-select .css-6q0nyr-Svg {
  width: 17px;
}

.form-control.month-select .css-tlfecz-indicatorContainer {
  padding: 7px 1px 7px 0 !important;
}

.form-control.month-select .css-1pahdxg-control:hover {
  border: 0;
}

.form-control.month-select .css-1pahdxg-control:hover {
  border-color: transparent;
}

.form-control.month-select .css-1pahdxg-control {
  border: 0;
  box-shadow: none;
  border-radius: none;
}

.form-control.month-select .css-1gtu0rj-indicatorContainer {
  padding: 0 1px 0 0;
}

.device-information .cs-from {
  padding: 0 6px !important;
}

.dashboard-card {
  position: relative;
}

.dashboard-card .page-loader {
  position: absolute;
}

.form-control.device-control,
.form-control.device-control:hover {
  width: 220px;
}

.rating-box-otr .inner-body {
  min-height: 412px;
}

.button-presses-otr .inner-body {
  min-height: 540px;
}

.other-offices-otr .inner-body {
  min-height: 348px;
}

.satisfaction-rate-otr .inner-body {
  min-height: 348px;
}

.over-time-otr .inner-body {
  min-height: 440px;
}

.usage-warning-otr .inner-body {
  min-height: 500px;
}

.fraud-warning-otr .inner-body {
  min-height: 500px;
}

.tol-revision {
  font-size: 16px;
}

.data-text {
  font-size: 20px;
  font-weight: 600;
}

.data-text span {
  color: #20a8d8;
  text-decoration: underline;
  padding: 0 5px;
}

.device-name {
  position: absolute;
  text-align: center;
  width: auto;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.inner-report {
  margin-top: 138px;
}

.welcom-modal {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin: 0 auto !important;
}

.res-nav {
  display: none;
}

.inline {
  display: inline;
}
.disbaled-download {
  pointer-events: none;
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .custon-select-control .css-yk16xz-control {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .expt-btn {
    font-size: 0;
  }

  .app-body {
    margin-top: 0 !important;
  }

  .main .container-fluid {
    padding: 0 !important;
  }

  .drop-zon {
    width: 100%;
  }

  .tab-pad-mobile .nav-tabs .nav-link {
    padding: 0.4rem 0.3rem;
  }

  .device-name {
    position: absolute;
    text-align: center;
    width: 100%;
    height: auto;
    top: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 12px;
    left: 0;
  }
}

@media (min-width: 576px) {
  .modal-movie-player.modal-dialog {
    max-width: 400px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .inner-card .title,
  .growth-value,
  .inner-card .value {
    font-size: 30px;
  }

  .acn-link {
    font-size: 0;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .rating-container {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .rating-container .item-container {
    margin-top: 10px;
  }

  .acn-link {
    font-size: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .container {
    max-width: 100% !important;
    width: 100%;
  }

  .form-control.month-select .css-1pahdxg-control,
  .form-control.month-select .css-yk16xz-control {
    min-height: 34px !important;
  }

  .satisfaction-ul {
    margin-bottom: 10px;
  }

  .rating-container {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .rating-container .item-container {
    margin-top: 10px;
  }

  .rating-container .item-container .item {
    height: 85px;
  }

  .rating-container .item-container .item .count {
    font-size: 20px;
  }

  .rating-container .item-container .item .percentage {
    font-size: 14px;
  }

  .dashboard-card .inner-body,
  .dashboard-card .inner-header {
    padding: 15px;
  }

  .dashboard-card .inner-card {
    padding: 15px;
    height: 100px;
  }

  .dashboard-card .inner-card .title {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .dashboard-card .inner-card .value {
    font-size: 30px;
    margin-bottom: 0;
  }

  .growth-value {
    font-size: 30px;
  }

  .growth-image {
    width: 80px;
  }

  .dashboard-header {
    padding: 12px 15px;
    height: auto;
  }

  .dashboard-card.mt-5 {
    margin-top: 1rem !important;
  }

  .device-information {
    margin-top: 10px;
  }

  .logo-image {
    width: 40px;
  }

  .device-information .form-control,
  .device-information .form-control:hover {
    height: 35px;
    width: 50%;
  }

  .acn-link,
  .expt-btn {
    margin: 0 0 0 8px;
    font-size: 0;
    position: absolute;
    top: -39px;
    right: 36px;
  }

  /*.action-buttons .acn-link{ right: 0; }*/
  .expt-btn {
    right: 30px;
    top: -40px;
  }

  .res-nav {
    right: 15px;
    position: absolute;
  }

  .device-information > .acn-link {
    right: 60px;
  }

  .acn-link img {
    width: 25px;
  }

  .acn-link + .acn-link {
    right: 10px;
  }

  .logout {
    right: 60px;
    margin: 0 0 0 8px;
    font-size: 0;
    position: absolute;
    top: -39px;
  }

  .action-buttons .logout {
    margin: 0 0 0 8px;
    font-size: 0;
    position: absolute;
    top: -39px;
    right: 0;
  }

  .top-nav {
    margin-left: 0;
  }

  .bar-container canvas {
    height: 400px !important;
  }

  .top-nav {
    position: absolute;
    bottom: 0;
    background: #fff;
    left: 0;
    width: 100%;
    display: block;
    box-shadow: 0 -5px 5px -5px rgba(0, 1, 1, 0.2) !important;
    border-bottom: solid 1px #e5e5e5;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .top-nav li {
    line-height: 34px;
    border-bottom: solid 1px #e5e5e5;
  }

  .top-nav li a {
    color: #828b93;
    padding: 0 15px;
    display: block;
  }

  .device-name {
    top: 42px;
  }

  .device-information {
    margin-bottom: 10px;
  }

  .top-nav.show {
    visibility: visible;
    opacity: 1;
    bottom: -103px;
  }

  .res-nav {
    display: inline;
  }

  .inline {
    display: inline;
  }

  .with-select .top-nav.show {
    bottom: -138px;
  }

  .action-buttons {
    position: absolute;
    top: 0;
    right: 60px;
  }

  .top-nav li {
    padding: 0;
  }

  .inner-container .acn-link {
    top: 17px;
    right: 98px;
  }
}
