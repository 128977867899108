@import "../../sharedcomponents/styles/mixins.scss";
@import "../../sharedcomponents/styles/variables.scss";

.interviews {
  .print-button {
    @include max-w($d-sm) {
      display: none;
    }
  }
  .key-metrics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @include max-w($d-lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include max-w($d-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include max-w($d-xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .chart-metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @include max-w($d-lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .text-metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @include max-w($d-lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
