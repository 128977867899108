.Auth {
  &_container {
    background-image: url('../../../../public/media/background/login-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    height: 100vh;
  }
  &_card {
    width: 40%;
    padding: 40px 15px;

    @media screen and (max-width: 767px) {
      width: 75%;
    }
  }
}
